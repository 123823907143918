<template>
    <div>
        <template v-if="list_member.length > 1">
            <h2>Envoi un Email à {{ nb_mail }} Client(s)</h2>
        </template>
        <template v-if="list_member.length==1">
            <h2>Envoyer un Email à <b>{{ list_member[0].first_name }} {{ list_member[0].last_name }}</b></h2>
        </template>
        <hr/>
        <br/>
        Titre de l'Email:
        <vs-input v-model="title" class="w-full"/>
        <br/>
        <br/>
        Template du message:
        <!--<vs-textarea v-model="template" />-->
        <ckeditor :editor="editor" v-model="template" :config="editorConfig"></ckeditor>
        <br/>
        Nombre d'Email à envoyer: <b>{{ list_member.length }}</b>
        <br/>
        <vs-divider/>
        <vs-row>
            <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
                <vs-button color="primary" type="filled" v-on:click="retour()">Retour</vs-button>
                <vs-button color="success" type="filled" v-on:click="send()" :disabled="send_mail">Envoyer</vs-button>
            </vs-col>
        </vs-row>
    </div>
</template>



<style lang="scss">
</style>

<script>

import autoRefresh from '@/assets/utils/autoRefresh'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

export default {
    data(){
        return{
            list_member:[],
            title : "",
            template: "Bonjour [PRENOM],",

            nb_mail: 0,
            send_mail: false,


            editor: ClassicEditor,
            editorData: '',
            editorConfig: {
                // The configuration of the editor.
            },
        }
	},
	mounted:function()
	{
        this.list_member = [];

        //autoRefresh
		autoRefresh.SetCallback(()=>
        {
            if( window.api_selected != undefined)
            {
                this.list_member = window.api_selected;
                window.api_selected = undefined;
            }
            if(this.list_member.length == 0 )
            {
                this.retour()
                return;
            }
            this.updateInfo()
        })
        autoRefresh.refresh()
    },
    watch:{
        $route (){
        },
        template(){
            this.updateInfo()
        }
    },
    methods:{
		retour()
		{
			this.$router.go(-1)
		},
        updateInfo()
        {
            this.nb_mail = 0;
            for( let i=0; i<this.list_member.length; i++)
            if( this.list_member[i].email )
                this.nb_mail++;
        },

        send()
		{
            if( this.send_mail == true)
                return;
            if(this.title.length == 0 )
            {
                alert("Aucun titre saisie !");
                return;
            }
            if(this.template.length == 0 )
            {
                alert("Aucun message saisie !");
                return;
            }
            if(this.list_member.length == 0 )
            {
                alert("Aucun client choisie !")
                return;
            }
            if(!confirm("Confirmer-vous l'envoi ? Cela peux durer plusieurs minutes ..."))
                return;
            
            //lock
            this.send_mail = true;

            //Get UID member
            let tab_uid = [];
            for( let i=0; i<this.list_member.length; i++)
                tab_uid.push( this.list_member[i].uid );

            //Send envoi
            this.$srvApi.Req('post', 'studio/emailgroup',{
                title    : this.title,
                template : this.template,
                tab_uid  : tab_uid.join(','),
            })
            .then((rep)=>{
                alert('Envoi effectué pour un total de '+rep.data+" Email")
                this.retour()
            });
		},
    }
};

</script>